import React from 'react';
import { Trash2 } from 'react-feather';
import PassengerDetail from '../types/createBookingRequest/PassengerDetail';

interface FlightsCreationDetailsProps {
  passenger: PassengerDetail;
}

const PassengerCreationDetails : React.FC<FlightsCreationDetailsProps> = ({ passenger }) => {
  const removePassenger = () => {

  };

  return (
    <div className='flex max-w-full'>
      <label htmlFor="passengerIndex" className="ml-2 px-1 text-xs bg-white text-gray-700 mb-2 relative z-20">Passenger Index</label>
        <input
          type="text"
          id={`passengerIndex_${passenger.Index}`}
          name="passengerIndex"
          className="
            appearance-none
            bg-white
            border
            border-gray-400
            rounded
            py-2
            pl-4
            leading-tight
            focus:outline-none
            focus:bg-white
            focus:border-gray-500"
          placeholder="0"
        />
      <div className="w-24">
        <label htmlFor="issuingOrgCode" className="ml-2 px-1 text-xs bg-white text-gray-700 mb-2 relative z-20">Issuer</label>
        <input
          type="text"
          id="issuingOrgCode"
          name="issuingOrgCode"
          className="
            max-w-full
            appearance-none
            bg-white
            border
            border-r-0
            border-gray-400
            rounded-l
            -mt-8
            py-2
            pl-4
            leading-tight
            focus:outline-none
            focus:bg-white
            focus:border-gray-500
            relative
            z-10"
          placeholder="KL"
        />
      </div>
      <div className="w-48 relative">
        <label htmlFor="flyingBlueNumber" className="ml-2 px-1 text-xs bg-white text-gray-700 mb-2 relative z-20">Number</label>
        <input
          type="number"
          id="flyingBlueNumber"
          name="flyingBlueNumber"
          className="
            w-48
            appearance-none
            bg-white
            border
            border-l-0
            border-gray-400
            rounded-r
            -mt-2
            py-2
            pr-4
            leading-tight
            focus:outline-none
            focus:bg-white
            focus:border-gray-500
            relative
            z-10"
          placeholder="0123456789"
        />
      </div>
      <div>
        <button onClick={removePassenger} className='mb-4'>
          <Trash2 className='p-1 rounded text-gray-600 hover:text-red-900 hover:bg-red-100' />
        </button>
      </div>
    </div>
  );
};

export default PassengerCreationDetails