import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import CreateBookingStatus from '../types/CreateBookingStatus';

interface fillFlightStates {
    fillFlightRequests: { [id: string]: { [id: number]: CreateBookingStatus }}
    put: (key: string, booking: { [id: number]: CreateBookingStatus}) => void;
    updateStatus: (key: string, id: number, newStatus: CreateBookingStatus) => void;
}

const useFillFlightStore = create<fillFlightStates>()(persist((set) => ({
    fillFlightRequests: {},
    put: (key: string, booking: { [id: number]: CreateBookingStatus}) => 
        set((state) => ({
            fillFlightRequests: { ...state.fillFlightRequests, [key]: booking}
        })),
    updateStatus: (key: string, id: number, newStatus: CreateBookingStatus) => 
        set((state) =>  ({
                    fillFlightRequests: { ...state.fillFlightRequests, [key]: {...state.fillFlightRequests[key], [id]: newStatus} }
                })
        )
}),
{
    name: "fill-flight-store"
}));

export default useFillFlightStore;