import React from 'react';
import FlightCreationDetails from './FlightCreationDetails';
import Flight from '../../../types/Flight';

interface FlightsCreationDetailsProps {
  flights: Flight[];
  onFlightChange: Function;
  onFlightDelete: Function;
}

const FlightsCreationDetails : React.FC<FlightsCreationDetailsProps> = ({ flights, onFlightChange, onFlightDelete }) => {
  var hasFlights = (flights && flights.length > 0);
  
  if(!hasFlights){
    return <form className='max-w-full'></form>
  }

  return (
    <form className='max-w-full max-h-full' onSubmit={(e) => e.preventDefault()}>
      {
          flights.map((flight, index) => (
            <FlightCreationDetails key={index} index={index} flight={flight} onFlightChange={onFlightChange} onFlightDelete={onFlightDelete}/>
          ))
        }
    </form>
  );
};

export default FlightsCreationDetails