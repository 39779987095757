import React, { useState } from "react";
import DisplayFlight from "../components/fillFlight/flightsSideBar/DisplayFlights";
import useFillFlightStore from "../stores/fillFlightStore";
import fillFlightService from "../services/fillFlightService";
import FillFlight from "../components/fillFlight/searchFlight/SearchFillFlight";
import FilledFlightItemList from "../components/fillFlight/flightsSideBar/FilledFlightItemList";

interface FillFlightsPageProps {
    children: React.ReactNode;
  }

const FillFlightsPage: React.FC<FillFlightsPageProps> = ({ children }) =>{
      return (
        <div className="grid grid-cols-main-content gap-4 overflow-hidden">
            <FilledFlightItemList></FilledFlightItemList>
            {children}
        </div>
      );
}

export default FillFlightsPage;