//shuffle an array in memory
function shuffle(array: Array<any>) {
    for (var i = 0; i < array.length; i++) {
        var temp = array[i]
        var randomIndex = getRandomInteger(i, array.length)
        array[i] = array[randomIndex]
        array[randomIndex] = temp
    }
}

// get some random integer within a range **strictly lower then the max**
function getRandomInteger(min: number, max: number): number {
    return Math.floor((Math.random() * (max - min)) + min)
}

const changeTextFieldNumberValue = (method: (value: number) => void) => {
    return (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => method(parseInt(value.target.value))
}

export { shuffle, changeTextFieldNumberValue }