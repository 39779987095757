import CreateBookingParameters from "../types/createBookingRequest/CreateBookingParameters";
import fillFligtRequest from "../types/fillFlightRequest/FillFlightRequest";
import {shuffle} from "../globalFunctions/utils";
import { toFlight } from "../globalFunctions/FlightId";
import Ssr from "../types/createBookingRequest/Ssr";
import FillFlightGroup from "../types/fillFlightRequest/FillFlightGroup";
import FillFlightFareType from "../types/fillFlightRequest/FillFlightFares";
import FillFlightSsr from "../types/fillFlightRequest/FillFlightSsr";

interface PassengerSsr {
    IsAdult: boolean
    Ssr: string | null
}

interface Group {
    Passengers: Array<PassengerSsr>
    ProductClass: string
}

const FillFlightDataService = {
    // take a look at this again since it is shit :) (I don't know)
    convertFillFlightRequestToBookingRequests(fillFlightRequest: fillFligtRequest, totalSpacesToFill: number): Array<CreateBookingParameters> {
        var allSsrs: PassengerSsr[] = this.convertSsrRequestsToSingleSsrs(fillFlightRequest.ssrs);

        // fill additional spaces with empty ssrs
        var additionalSpacesToFill = totalSpacesToFill - allSsrs.length;
        for (var i = 0; i < additionalSpacesToFill; i++) {
            allSsrs.push({
                Ssr: null, 
                IsAdult: true
            })
        }

        // shuffle spaces for random ssrs to different groups
        shuffle(allSsrs);

        var groups: Array<Group> = this.convertSsrAndPassengersToGroups(allSsrs, fillFlightRequest.groups);

        // shuffle groups for random fare types to random groups
        shuffle(groups);

        var k = 0;
        for (const fareType of fillFlightRequest.fareTypes) {
            for (var i = 0; i < fareType.Total; i++) {
                groups[k].ProductClass = fareType.FareType;
                k++;
            }
        }

        return this.convertGroupsToBookingParameters(groups, fillFlightRequest.flightId);
    },

    addFareTypesToGroups(groups: Group[], fareTypes: FillFlightFareType[]) {
        var currIndex = 0;
        fareTypes.forEach(x => {
            for (var i = 0; i < x.Total; i++) {
                groups[currIndex].ProductClass = x.FareType
                currIndex++;   
            }
        })
    },

    convertSsrAndPassengersToGroups(passengerSsrs: PassengerSsr[], groupRequests: FillFlightGroup[]): Array<Group> {
        var groups: Array<Group> = [];
        
        var i = 0;
        var j = 0;
        while (i < passengerSsrs.length) {
            var newGroup: Array<PassengerSsr> = []
            if (j < groupRequests.length) {
                for (var n = 0; n < groupRequests[j].Total; n++) {
                    newGroup = []

                    for (var k = i; k < groupRequests[j].Adults + i + groupRequests[j].Children; k++) {
                        if (k >= i + groupRequests[j].Adults) {
                            passengerSsrs[k].IsAdult = false
                        }
                        newGroup.push(passengerSsrs[k])
                    }
                    groups.push({
                        Passengers: newGroup,
                        ProductClass: "basic"
                    })

                    i += groupRequests[j].Adults + groupRequests[j].Children;
                }
                j++;
            } else {
                newGroup.push(passengerSsrs[i]);
                i++;

                groups.push({
                    Passengers: newGroup,
                    ProductClass: "basic"
                })
            }
        }

        return groups;
    },

    // fill flight ssrs should be converted to individual ssrs
    convertSsrRequestsToSingleSsrs(flightSsrRequests: FillFlightSsr[]): PassengerSsr[] {
        var allSsrs: PassengerSsr[] = []

        //first convert all the ssr requests to single ssrs
        for (var i = 0; i < flightSsrRequests.length; i++) {
            const currSsr = flightSsrRequests[i]

            for (var j = 0; j < currSsr.Total; j++) {
                allSsrs.push({
                    Ssr: currSsr.Code, 
                    IsAdult: true
                })
            }
            allSsrs = allSsrs;
        }

        return allSsrs;
    },

    // validate fillFlightRequest:
    // there should not be more ssr requests then passengers
    // there should not be more fare types then groups
    // there should not be more passengers in groups then total passengers
    validation(fillFlightRequest: fillFligtRequest, totalSpacesToFill: number): string[] {
        const allErrors: string[] = []
        var totalPassengersInGroups = fillFlightRequest.groups.reduce((a, b): number => a + (b.Total*(b.Adults + b.Children)), 0)
        var totalGroups = totalSpacesToFill - totalPassengersInGroups + fillFlightRequest.groups.reduce((a, b) => a + b.Total, 0);
        if (fillFlightRequest.fareTypes.reduce((a, b) => a + b.Total, 0) > totalGroups) allErrors.push(`number of fare types cannot exceed number of bookings`);

        if (fillFlightRequest.ssrs.reduce((a, b) => a + b.Total, 0) > totalSpacesToFill) allErrors.push("total number of ssrs cannot exceed number of passengers"); 

        if (fillFlightRequest.groups.reduce((a, b) => a + b.Adults + b.Children, 0) > totalSpacesToFill) allErrors.push("total number of groups cannot be larger then total passengers");

        return allErrors;
    },

    convertGroupsToBookingParameters(groups: Array<Group>, flightId: string): Array<CreateBookingParameters> {

        // helper function
        function CreateSsrs(passengers: Array<PassengerSsr>, shouldBeAdult: boolean, addIndex: number): Ssr[] {
            return passengers
                .filter(x => x.IsAdult == shouldBeAdult)
                .map((x, index) => {
                    if (x.Ssr == null) return null;
                    return {
                        ssrCode: x.Ssr,
                        passengerIndex: index + addIndex
                    }
                })
                .filter(x => x != null) as Ssr[];
        }

        return groups.map(x => {
            var flight = toFlight(flightId);

            
            var adultPassengers = x.Passengers.reduce((a, b) => {
                if (b.IsAdult) return a + 1
                return a
            }, 0)

            var childPassengers = x.Passengers.reduce((a, b) => {
                if (!b.IsAdult) return a + 1
                return a
            }, 0);

            var adultsSsrs: Array<Ssr> = CreateSsrs(x.Passengers, true, 0);

            var childrenSsrs: Array<Ssr> = CreateSsrs(x.Passengers, false, adultPassengers)

            flight.Ssrs = adultsSsrs.concat(childrenSsrs);
            return {
                ContactDetails: null,
                FlightOptions: [flight],
                PassengerDetails: [],
                PassengerTypes: {
                    Adults: adultPassengers,
                    Children: childPassengers
                },
                ProductClass: x.ProductClass,
                ThirdPartyRecordLocator: {
                    RecordCode: "",
                    OwningSystemCode: ""
                }
            }
        })
    },

    // giveMeSomeRandomBookingParam(): CreateBookingParameters {
    //     const newParams: CreateBookingParameters = {
    //         ContactDetails: null,
    //         FlightOptions: [{DepartureDate: null, Origin: null, Destination: null, FlightNumber: null, CarrierCode: null, IncludeReturnFlight: null, Ssrs: []}],
    //         PassengerDetails: [],
    //         PassengerTypes: {
    //             Adults: 2,
    //             Children: 0
    //         },
    //         ProductClass: "basic",
    //         ThirdPartyRecordLocator: {
    //             RecordCode: "",
    //             OwningSystemCode: ""
    //         }
    //     } 
    //     return newParams;
    // }    
}

export default FillFlightDataService;