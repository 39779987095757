import Flight from "../types/Flight";

const toFlight = function(flightId : string | null): Flight {
    if (!flightId) return {
        CarrierCode: null,
        FlightNumber: null,
        Origin: null,
        Destination: null,
        DepartureDate: null,
        IncludeReturnFlight: null,
        Ssrs: []
    };
    var origin = flightId.substring(0, 3);
    var destination = flightId.substring(3, 6);
    var departureDate = flightId.substring(6, 10) + "-" + flightId.substring(10, 12) + "-" + flightId.substring(12, 14);
    var carrier = flightId.substring(14, 16);
    var flightNumber = Number.parseInt(flightId.substring(16, 20));
    return {
        CarrierCode: carrier,
        FlightNumber: flightNumber,
        Origin: origin,
        Destination: destination,
        DepartureDate: departureDate,
        IncludeReturnFlight: null,
        Ssrs: []
    }
}

const toFlightId = function(flight: Flight): string | null{
    if (!flight.Origin || !flight.Destination || !flight.FlightNumber || !flight.CarrierCode || !flight.DepartureDate) return null;
    return flight.Origin + flight.Destination + flight.DepartureDate.replaceAll("-", '') + flight.CarrierCode + flight.FlightNumber
}

export { toFlight, toFlightId };