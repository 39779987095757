import React, { useEffect } from 'react';
import useFillFlightStore from '../../../stores/fillFlightStore';
import LinearProgress from '@mui/material/LinearProgress';
import FlightCard from './FlightCard';
import { useNavigate } from 'react-router-dom';
import { toFlight } from '../../../globalFunctions/FlightId';

interface flightReference {
  reference: string
  chosen: boolean
}

const FilledFlightItem : React.FC<flightReference> = ({reference, chosen}) => {
  const allFlights = useFillFlightStore((state) => state.fillFlightRequests);
  
  const bookingsStatussesObjects = allFlights[reference];
  // const bookingsStatussesObjects = Object.entries(allFlights).find(([x, y]) => x == reference)![1];
  const totalCompleted = Object.entries(bookingsStatussesObjects).map(([id, bookingStatus]) => bookingStatus.result).reduce((a, b) => {
    if (b != null)
      return a + 1
    return a
  }, 0)

  const navigate = useNavigate()

  const flight = toFlight(reference)

  const redirect = (reference: string) => {
    navigate("/fillFlight/" + reference)
  }

  const total = Object.entries(bookingsStatussesObjects).length

  const progress = total > 0 ? totalCompleted / total * 100: 0;

  return (
    <li key={reference} className='my-3'>
      <FlightCard chosen={chosen} flightNumber={`${flight.CarrierCode}${flight.FlightNumber}`} date={`${flight.DepartureDate?.toString()}`} route={`${flight.Origin} - ${flight.Destination}`} bookings={total} bookingsCompleted={totalCompleted} onClick={() => redirect(reference)} ></FlightCard>
    </li>
  );
};

export default FilledFlightItem;