import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import BookingService from "../../../services/bookingService";
import fillFlightService from "../../../services/fillFlightService";
import useFlightRequestStore from "../../../stores/FlightRequestStore";
import useFillingFlightStore from "../../../stores/IsFillingFlightStore";
import FlightInformation from "../../../types/flightInformation/FlightInformation";
import DetailsPanel from "../../DetailsPanel";
import BrandedFareFlightCreation from "../flightCreationDetails/BrandedFareFlightCreation";
import PassengerFlightCreation from "../flightCreationDetails/PassengerFlightCreationList";
import SsrFlightCreation from "../flightCreationDetails/SsrFlightCreationList";
import FlightInformationComponent from "./FlightInformation";
import { RefreshCcw } from "react-feather";

const FillFlight: React.FC = () =>{
    const [filling, setFilling] = useState(false);
    const { fillFlightRequest, setFlightId, addPassengerGroup, addSsr, reset } = useFlightRequestStore();
    const { fillingFlight, setFillingFlight } = useFillingFlightStore();
    const { flightId } = useParams()
    const [ flightInformation, setFlightInformation ] = useState<FlightInformation | null>(null);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (flightId == null) return;
        setFlightId(flightId);
        try {
            BookingService.getFlightInformation(flightId).catch(Error).then(x => {
                if (x instanceof Error) {
                    const emptyFlightInfo: FlightInformation = {
                        "flightAvailability": {
                            "seatCapacity": 0,
                            "seatsAvailable": 0,
                            "seatsSold": 0
                        }
                    }
                    setFlightInformation(emptyFlightInfo);
                } else {
                    setFlightInformation(x);
                }
            });
        } catch (err) {
            console.log(`request failed trying again`);
            console.log(err);
        }
        
    }, [flightId])

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (fillingFlight) {
              event.preventDefault();
              
            }
          };
          window.addEventListener("beforeunload", handleBeforeUnload);
          return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [fillingFlight])

    const updateFlightIdState = async () => {
        if (fillFlightRequest.flightId == null) return;
        setFilling(true);
        await fillFlightService.fillFlight(fillFlightRequest, setFillingFlight);
        setFilling(false);
    }

    return (
        <>
            <form onSubmit={(e) => {
            e.preventDefault()
            // Call validateRequest and capture errors
            const validationErrors = fillFlightService.validateRequest(fillFlightRequest);

            if (validationErrors.length > 0) {
                // If there are errors, update the state to display them and block submission
                for (const i of validationErrors) {
                    toast.error(i)
                }
                
                return;
            }
            // Clear errors if validation passes
            updateFlightIdState()

            }} className="flex flex-col overflow-hidden relative text-sm">
                <FlightInformationComponent
                    flightId={fillFlightRequest.flightId}
                    flightInformation={flightInformation}/>
                { flightInformation != null && flightInformation?.flightAvailability.seatsAvailable > 0 ? 
                <>
                    <div className="flex flex-1 overflow-x-auto gap-4">  
                        <DetailsPanel title="Branded fares">
                            <BrandedFareFlightCreation></BrandedFareFlightCreation>
                        </DetailsPanel>
                        <DetailsPanel title={"Passengers"} addFunction={() => addPassengerGroup()}>
                            <PassengerFlightCreation></PassengerFlightCreation>
                        </DetailsPanel>
                        <DetailsPanel title={"Ssrs"} addFunction={() => addSsr()}>
                            <SsrFlightCreation></SsrFlightCreation>
                        </DetailsPanel>
                    </div>
                    <div className="absolute bottom-0 right-0 flex gap-2">
                        <button 
                            type="button"
                            onClick={(e) =>{
                                e.preventDefault();
                                reset();
                            }} 
                                className='w-min h-min m flex-auto'>
                            <div className="bg-gray-300 rounded w-9 h-9 hover:bg-red-100">
                                <RefreshCcw width={36} height={36} className='p-1 text-gray-600 p-2 hover:text-red-900 transition-transform duration-500 hover:-rotate-180' />
                            </div>
                        </button>
                        <button
                            data-testid="create-button"
                            className={`bg-green-500 flex-auto hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${filling ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={filling}
                        >
                            {filling ? 'Filling Flight...' : 'Fill Flight'}
                        </button>    
                    </div>
                </>
                : 
                <></>
                }
                
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                theme="light"
                />
        </>
      );
}

export default FillFlight;