import { create } from 'zustand';

interface FlightIdState {
    flightId: string | null;
    set: (flightId: string | null) => void;
}

const useFlightIdStore = create<FlightIdState>()((set) => ({
    flightId: null,
    set: (flightId: string | null) => set((state) => ({flightId: flightId}))
}));

export default useFlightIdStore;