import { create } from 'zustand';

interface fillingFlightState {
    fillingFlight: boolean;
    setFillingFlight: (fillingFlight: boolean) => void;
}

const useFillingFlightStore = create<fillingFlightState>()((set) => ({
    fillingFlight: false,
    setFillingFlight: (isFillingFlight: boolean) => set((state) => ({fillingFlight: isFillingFlight}))
}));

export default useFillingFlightStore;