import { TextField } from "@mui/material";
import { Trash2 } from "react-feather";
import { changeTextFieldNumberValue } from "../../../globalFunctions/utils";
import useFlightRequestStore from "../../../stores/FlightRequestStore";
import FillFlightGroup from "../../../types/fillFlightRequest/FillFlightGroup";

interface PassengerFlightCreationItemProps {
    fillFlightGroup: FillFlightGroup,
    removeMethod: () => void;
    addMethod: (newPassengerGroup: FillFlightGroup) => void;
}

const PassengerFlightCreationItem: React.FC<PassengerFlightCreationItemProps> = ({fillFlightGroup, removeMethod, addMethod}) => {
    const { fillFlightRequest } = useFlightRequestStore();

    const changeAdults = (adults: number) => {
        addMethod({
            Adults: adults,
            Children: fillFlightGroup.Children,
            Total: fillFlightGroup.Total
        })
    }

    const changeChildren = (children: number) => {
        addMethod({
            Adults: fillFlightGroup.Adults,
            Children: children,
            Total: fillFlightGroup.Total
        })
    }

    const changeTotal = (total: number) => {
        addMethod({
            Adults: fillFlightGroup.Adults,
            Children: fillFlightGroup.Children,
            Total: total
        })
    }

    return (
        <>
            <TextField 
                className=""
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeTotal)}
                placeholder="number of groups"
                value={fillFlightGroup.Total}
                slotProps={{
                    htmlInput: {
                        min: 0
                    }
                }}
            />
            <TextField 
                className=""
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeAdults)}
                value={fillFlightGroup.Adults}
                placeholder="adults"
                slotProps={{
                    htmlInput: {
                        min: 0,
                        max: 20
                    }
                }}
            />
            <TextField 
                className=""
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(changeChildren)}
                placeholder="children"
                value={fillFlightGroup.Children}
                slotProps={{
                    htmlInput: {
                        min: 0,
                        max: 20
                    }
                }}
            />
            <p>{(fillFlightGroup.Adults + fillFlightGroup.Children) * fillFlightGroup.Total}</p>
            <button 
                type="button"
                onClick={(e) =>{
                    e.preventDefault();
                    removeMethod();
                }} 
                    className='w-min h-min m'>
                <Trash2 className='p-1 rounded text-gray-600 hover:text-red-900 hover:bg-red-100' />
            </button>
        </>
    )
}

export default PassengerFlightCreationItem;