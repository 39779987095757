import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BookingService from '../../../services/bookingService';
interface DeleteBookingButtonProps {
  bookingReference: string;
  cancelled: boolean;
  onCancelBooking: Function;
}

const DeleteBookingButton : React.FC<DeleteBookingButtonProps> = ({ bookingReference, cancelled, onCancelBooking }) => {
  const [cancelingBooking, setCanceling] = useState(false);

  const cancelBooking = async (bookingReference: string, onCancelBooking: Function) => {
    setCanceling(true);

    try {
      await BookingService.cancelBookingRequest(bookingReference);
      onCancelBooking(bookingReference);
      toast.success('Cancelled booking.');
    } catch (error) {
      toast.error('Failed to cancel booking.');
      console.error('Fetch error:', error);
    } finally {
      setCanceling(false);
    }
  };

  return (
    <button
      data-testid="booking-cancel-button"
      className={`bg-gray-300 hover:bg-red-500 hover:text-white font-bold py-1 px-2 rounded disabled:bg-gray-200 disabled:text-gray-400`}
      onClick={() => cancelBooking(bookingReference, onCancelBooking)}
      disabled={cancelled}
      >{cancelingBooking ? 'Canceling' : 'Cancel'}</button>
  );
};

export default DeleteBookingButton;
