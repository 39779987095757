import { useState, useEffect } from 'react';
import BookingReferenceList from '../components/createBooking/createdBookingsSidebar/CreatedBookingItemList';
import { ToastContainer, toast } from 'react-toastify';
import BookingService from '../services/bookingService';
import CreateBookingParameters from '../types/createBookingRequest/CreateBookingParameters';
import BookingReferenceItem from '../types/CreatedBooking';
import CreateBooking from '../components/createBooking/createdBookingsSidebar/CreateBooking';

function CreateBookingsPage() {
  const [creatingBooking, setCreating] = useState(false);
  const [bookingCreationHistory, setBookingCreationHistory] = useState<BookingReferenceItem[]>([]);
  const environment = process.env.REACT_APP_ENVIRONMENT;
  useEffect(() => {
    // Load bookings from local storage when the component mounts
    const savedBookings: BookingReferenceItem[] = JSON.parse(localStorage.getItem('bookingCreationHistory') || '[]') as BookingReferenceItem[];
    setBookingCreationHistory(savedBookings);
  }, []);

  const createBooking = async (_: any, bookingParams: CreateBookingParameters) => {
    console.log("Creating booking...");
    setCreating(true);

    try {
      const data = await BookingService.createBookingRequest(bookingParams);
      const bookingReference = data.bookingReference;
      const updatedBookings = [...bookingCreationHistory, { "reference": bookingReference, "cancelled": false }];

      setBookingCreationHistory(updatedBookings);

      localStorage.setItem('bookingCreationHistory', JSON.stringify(updatedBookings));

      toast.success('Booking created successfully!');

    } catch (error) {

      console.error('Fetch error:', error);
    
      if (error instanceof Error) {
        toast.error(`${error.message}`);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setCreating(false);
    }
  };

  const handleCancelBooking = (bookingReference: string) => {
    setBookingCreationHistory(currentBookings => {
      const updatedBookings = currentBookings.map(booking =>
        booking.reference === bookingReference ? { ...booking, cancelled: true } : booking
      );
      localStorage.setItem('bookingCreationHistory', JSON.stringify(updatedBookings));
      return updatedBookings
    }
    );
  };

  return (
    <div className="grid grid-cols-main-content gap-4 overflow-hidden">
      <BookingReferenceList bookings={bookingCreationHistory} onCancelBooking={handleCancelBooking} />
      <CreateBooking createBooking={createBooking} creating={creatingBooking}/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CreateBookingsPage;
