import React from "react";

interface FlightCardProps {
  flightNumber: string;
  date: string;
  route: string;
  bookings: number;
  bookingsCompleted: number;
  onClick: Function;
  chosen: boolean;
}

const FlightCard: React.FC<FlightCardProps> = ({
  flightNumber,
  date,
  route,
  bookings,
  bookingsCompleted,
  onClick,
  chosen
}) => {
  return (
    <div
      className={`flex flex-1 items-center justify-between py-1 px-4 rounded-lg shadow-md hover:bg-gray-300 ${
        chosen ? "bg-gray-300" : "bg-gray-100"
      }`}
      onClick={() => onClick()}
      // Tailwind and inline MUI styling together
    >
      <div className="flex flex-col">
        <p className="font-bold textgray-800">
          {flightNumber}
        </p>
        <p className="text-gray-600">
          {route}
        </p>
      </div>

      <div className="flex flex-col items-end">
        <p className="text-gray-800" >
          {date}
        </p>
        <p className="text-gray-500 text-xs">
          {bookingsCompleted}/{bookings} Bookings Created
        </p>
      </div>
    </div>
  );
};

export default FlightCard;
