import React, { useState, useEffect } from 'react';
import HeaderBar from './components/HeaderBar';
import BookingReferenceItem from './types/CreatedBooking';
import CreateBookingsPage from './pages/CreateBookingPage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import FillFlightsPage from './pages/FillFlightsPage';
import SearchFillFlight from './components/fillFlight/searchFlight/SearchFillFlight';
import FillFlight from './components/fillFlight/fillFlight/FillFlight';

function App() {
  const [bookingCreationHistory, setBookingCreationHistory] = useState<BookingReferenceItem[]>([]);
  const environment = process.env.REACT_APP_ENVIRONMENT;
  useEffect(() => {
    // Load bookings from local storage when the component mounts
    const savedBookings: BookingReferenceItem[] = JSON.parse(localStorage.getItem('bookingCreationHistory') || '[]') as BookingReferenceItem[];
    setBookingCreationHistory(savedBookings);
  }, []);

  return (
    <Router>
      <div className="
        grid
        grid-rows-main
        h-screen
        w-screen
        gap-4
        p-4
        bg-gray-100
        max-w-3xl
        mx-auto
        relative">
        <HeaderBar environment={environment ?? ""} />
        <Routes>
          <Route path="/" element={<Navigate to="/CreateBookings" replace />} />
          <Route path="/CreateBookings" element={<CreateBookingsPage/>} />
          <Route path="/FillFlight" element={<FillFlightsPage><SearchFillFlight/></FillFlightsPage>} />
          <Route path='/FillFlight/:flightId' element={<FillFlightsPage><FillFlight/></FillFlightsPage>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
