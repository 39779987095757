import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toFlight } from '../../../globalFunctions/FlightId';
import Flight from '../../../types/Flight';
import FlightInformation from '../../../types/flightInformation/FlightInformation';

interface FlightInformationComponentProps {
  flightId: string,
  flightInformation: FlightInformation | null
}

const FlightInformationComponent: React.FC<FlightInformationComponentProps> = ( {flightId, flightInformation} ) => {
  const [ flight, setFlight ] = useState<Flight>(toFlight(flightId));

  useEffect(() => {
    setFlight(toFlight(flightId))
  }, [flightId])

  return (
    <Accordion className="mb-3">
      <AccordionSummary
        className="bg-white shadow p-4 flex justify-between items-center space"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content">
        {/* Flight Info */}
        <div className="flex flex-col">
          <span className="text-lg font-semibold">{`${flight.CarrierCode}${flight.FlightNumber}`}</span>
          <span className="text-sm text-gray-500">{`${flight.DepartureDate} ${flight.Origin}-${flight.Destination}`}</span>
        </div>

        {/* Seat and Booking Info */}
        <div className="text-sm">
          <span className="font-medium">Available seats: </span> 
          { flightInformation != null && flightInformation.flightAvailability.seatsAvailable > 0 ?`${flightInformation?.flightAvailability.seatsAvailable} / ${flightInformation?.flightAvailability.seatCapacity}` : `flight fully booked`}
        </div>
      </AccordionSummary>
      {/*add this back in when there is more information returned by the flight information */}
      {/* <AccordionDetails>
        <div className="">
          <div className="text-sm">
            <span className="font-medium">Bookings on flight:</span> 25
          </div>
          <div className="text-sm">
            <span className="font-medium">5B15:</span> 10
          </div>
          <div className="text-sm">
            <span className="font-medium">5B20:</span> 8
          </div>
          <div className="text-sm">
            <span className="font-medium">5B25:</span> 6
          </div>
          <div className="text-sm">
            <span className="font-medium">BIKE:</span> 1
          </div>
          <div className="text-sm">
            <span className="font-medium">DIVE:</span> 1
          </div>
          <div className="text-sm">
            <span className="font-medium">GOLF:</span> 1
          </div>
        </div>
      </AccordionDetails> */}
    </Accordion>
  );
};

export default FlightInformationComponent;