import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFlightIdStore from "../../../stores/FlightIdStore";
import useFlightRequestStore from "../../../stores/FlightRequestStore";
import DetailsPanel from "../../DetailsPanel";
import SearchFlight from "./SearchFlight";

const SearchFillFlight: React.FC = () =>{
    const [filling, setFilling] = useState(false);
    const { flightId } = useFlightIdStore();
    const { fillFlightRequest, setFlightId: setFillFlightRequest } = useFlightRequestStore();
    const navigate = useNavigate()
    const updateFlightIdState = async () => {
        if (flightId == null) return;
        setFilling(true);
        setFillFlightRequest(flightId);
        setFilling(false);
        navigate(flightId);
    }

    return (
        <form onSubmit={(e) => {
        e.preventDefault()
        updateFlightIdState()
        }} className="flex flex-col overflow-hidden relative">
            <div className="flex flex-1 overflow-x-auto gap-4">
                <DetailsPanel title="Search flight">
                    <SearchFlight />
                </DetailsPanel> 
            </div>
            <button
                data-testid="create-button"
                className={`absolute bottom-0 right-0 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${filling ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={filling}
            >
                Choose flight
            </button>    
        </form>
      );
}

export default SearchFillFlight;