import React, { useEffect, useState } from 'react';
import { Trash2 } from 'react-feather';
import { Autocomplete, TextField } from '@mui/material';
import Route from '../../../types/Route';
import BookingService from '../../../services/bookingService';
import { toCarrierAndNumber } from '../../../globalFunctions/flightNumber';
import IncludeReturnFlight from '../../../types/createBookingRequest/IncludeReturnFlight';
import CustomDatePicker from '../../CustomDatePicker';
import Flight from '../../../types/Flight';

interface FlightCreationDetailsProps {
  index: number;
  flight: Flight;
  onFlightChange: Function;
  onFlightDelete: Function;
  canDelete?: boolean;
  canHaveReturnFlight?: boolean;
  required?: boolean;
}

const FlightCreationDetails : React.FC<FlightCreationDetailsProps> = ({ index, flight, onFlightChange, onFlightDelete, canDelete = true, canHaveReturnFlight = true, required = false }) => {
  const [hasReturnFlight, setReturnFlightCheckbox] = useState(false);
  const [origins, setOrigins] = useState<string[]>([]);
  const [destinations, setDestinations] = useState<string[]>([]);
  const [flightNumbers, setFlightNumbers] = useState<string[]>([]);
  const [routes, setRoutes] = useState<Route[]>([]);

  const [origin, setOrigin] = useState<string | null>(flight.Origin);
  const [destination, setDestination] = useState<string | null>(flight.Destination);
  const [flightNumber, setFlightNumber] = useState<string | null>(null);
  const [departureDate, setDepartureDate] = useState<string | null>(null);
  const [returnDate, setReturnDate] = useState<string | null>(null);

  const [allowedDates, setAllowedDates] = useState([])
  const [allowedReturnDates, setAllowedReturnDates] = useState([])

  useEffect(() => {
      // Load bookings from local storage when the component mounts
      BookingService.getRoutes()
        .then((data) => {
          setRoutes(data);
        });
    }, []);

  var filterRoute = function(origin: string | null, destination: string | null, flightNumber: string | null) {
    return routes
      .filter((route) => !origin || route.origin === origin)
      .filter((route) => !destination || route.destination === destination)
      .filter((route) => !flightNumber || route.flightNumbers.map(x => x.carrierCode + x.flightNumber.toString()).includes(flightNumber));
  }

  useEffect(() => {
    setOrigins([...new Set(filterRoute(null, destination, flightNumber).map((route) => route.origin))]);
    setDestinations([...new Set(filterRoute(origin, null, flightNumber).map((route) => route.destination))]);
    setFlightNumbers([...new Set(filterRoute(origin, destination, null).flatMap((route) => route.flightNumbers).map((FlightReference) => FlightReference.carrierCode + FlightReference.flightNumber))]);
  }, [origin, flightNumber, destination, routes])

  useEffect(() => {
    updateDepartureDate(getDateString(departureDate));
    BookingService.getRoutes(getDateString(departureDate), getDateString(returnDate))
      .then((res) => {
        setRoutes(res)
      })
  }, [departureDate])

  useEffect(() => {
    
    updateReturnDate(getDateString(returnDate));
    BookingService.getRoutes(getDateString(departureDate), getDateString(returnDate))
      .then((res) => {
        setRoutes(res)
      })
  }, [returnDate])

  var onMonthUpdate = function(allowedDateFinder: Function) {
    return function(year: Number, month: Number) {
      allowedDateFinder(year, month)
    }
  }

  const filterDepartureAllowedDates = (res: any) => {
    if (returnDate) res = res.filter((x: string) => new Date(x) < new Date(getDateString(returnDate)!));
    return res;
  }

  const filterReturnAllowedDates = (res: any) => {
    if (departureDate) res = res.filter((x: string) => new Date(x) > new Date(getDateString(departureDate)!));
      
    return res;
  }

  var findAllowedDepartureDates = function(year: Number, month: Number) {
    if (month && year) {
      BookingService.getDates(origin, destination, flightNumber, year.toString(), month.toString()).then((res) => {
        setAllowedDates(filterDepartureAllowedDates(res));
      });
    }
  }

  var findAllowedReturnDates = function(year: Number, month: Number) {
    if (month && year) {
      BookingService.getDates(destination, origin, null, year.toString(), month.toString()).then((res) => {
        setAllowedReturnDates(filterReturnAllowedDates(res));
      });
    }
  }

  useEffect(() => {
    updateOrigin(origin);
  }, [origin])

  useEffect(() => {
    updateDestination(destination)
  }, [destination])

  useEffect(() => {
    updateFlightNumber(flightNumber)
  }, [flightNumber])

  const onReturnFlightToggleChange = () => {
    let updatedHasReturnFlight = !hasReturnFlight;
    setReturnFlightCheckbox(updatedHasReturnFlight);
    toggleReturnFlight(updatedHasReturnFlight);
  };

  const updateOrigin = (origin: string | null) => {
    var updatedFlight = {
      ...flight,
      Origin: origin
    }

    onFlightChange(index, updatedFlight);
  };

  const updateDestination = (destination: string | null) => {
    var updatedFlight = {
      ...flight,
      Destination: destination
    }

    onFlightChange(index, updatedFlight);
  };

  const updateFlightNumber = (flightNumber: string | null) => {
    var splitted = toCarrierAndNumber(flightNumber)
    var updatedFlight = {
      ...flight,
      FlightNumber: splitted?.flightNumber,
      CarrierCode: splitted?.carrierCode
    }

    onFlightChange(index, updatedFlight)
  }

  function formatDate(date: Date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


  const getDateString = (date: string | null) => {
    if (!date) return null;
    const newDate = new Date(date)
    return formatDate(newDate)
  }

  const updateDepartureDate = (date: string | null) => {
    var updatedFlight = {
      ...flight,
      DepartureDate : getDateString(date)
    }

    onFlightChange(index, updatedFlight);
  };

  const toggleReturnFlight = (updatedHasReturnFlight: boolean) => {
    var includeReturnFlight: any = null;

    if(updatedHasReturnFlight){
      includeReturnFlight = { "returnDate": null };
    }

    var updatedFlight = {
      ...flight,
      IncludeReturnFlight: includeReturnFlight
    }
    
    onFlightChange(index, updatedFlight);
  }

  const updateReturnDate = function(date: any | null) {
    var stringDate = getDateString(date);
    var dateProvided = stringDate && stringDate.replace(/\s+/g, '') !== "";
    var includeReturnFlight: IncludeReturnFlight | null = null;
    
    if (dateProvided){
      const newIncludeReturnFlight: IncludeReturnFlight = { ReturnDate: stringDate };
      includeReturnFlight = newIncludeReturnFlight;
    }

    var updatedFlight = {
      ...flight,
      IncludeReturnFlight: includeReturnFlight
    }
    onFlightChange(index, updatedFlight);
  };

  return (
    <div className='border-b-2 mb-4' data-testid='flight-creation-details'>
      <div className='flex max-w-full justify-between pb-4'>
        <div className='w-32' data-testid="drop-down">
          <label htmlFor="origin" className="block text-gray-700 font-bold mb-2">Origin</label>
          <Autocomplete 
            options={origins}
            size='small'
            autoHighlight
            onChange={(e, newValue) => setOrigin(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="origin"
                name="origin"
                className="custom-textfield"
                required={required}
                />
            )}
          />
        </div>
        <div className='w-32' data-testid="drop-down">
          <label htmlFor="destination" className="block text-gray-700 font-bold mb-2">Destination</label>
          <Autocomplete 
            options={destinations}
            size='small'
            autoHighlight
            onChange={(e, newValue) => setDestination(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="destination"
                name="destination"
                className="custom-textfield"
                required={required}
              />
            )}
          />
        </div>
      </div>
      <div className="max-w-full pb-4" data-testid="drop-down">
        <label htmlFor="FlightNumber" className="block text-gray-700 font-bold mb-2">Flight number</label>
        <Autocomplete 
            options={flightNumbers}
            getOptionLabel={(option) => option.toString()}
            size='small'
            autoHighlight
            onChange={(e, newValue) => setFlightNumber(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                id="FlightNumber"
                name="FlightNumber"
                className="custom-textfield"
              />
            )}
          />
      </div>
      <div className="max-w-full pb-4" data-testid="return-date">
        { <CustomDatePicker required={required} title="Departure" onDateChange={setDepartureDate} onMonthChange={onMonthUpdate(findAllowedDepartureDates)} allowedDates={allowedDates} />}
      </div>
      { canHaveReturnFlight ? <label className="flex items-center space-x-2 pb-4">
        <input
          type="checkbox"
          checked={hasReturnFlight}
          onChange={onReturnFlightToggleChange}
          className="form-checkbox h-5 w-5"
        />
        <span>Create return flight</span>
      </label> : <div/> }
      <div className="max-w-full pb-4" data-testid="departure-date">
        { hasReturnFlight && <CustomDatePicker title="Return" onDateChange={setReturnDate} onMonthChange={onMonthUpdate(findAllowedReturnDates)} allowedDates={allowedReturnDates}  /> }
      </div>
      {canDelete ? 
      <div>  
        <button id="someInvisableButton"></button>
        <button data-testid="deleteFlightOptions" onClick={(e) =>{
            e.preventDefault();
            onFlightDelete(e, index);
        }} 
            className='mb-4'>
          <Trash2 className='p-1 rounded text-gray-600 hover:text-red-900 hover:bg-red-100' />
        </button>
      </div> : <div/>
      }
      
    </div>
  );
};

export default FlightCreationDetails