import React, { useState } from 'react';
import FilledFlightItem from './FilledFlightItem';
import useFillFlightStore from '../../../stores/fillFlightStore';
import CreateBookingStatus from '../../../types/CreateBookingStatus';
import { useParams } from 'react-router-dom';

const FilledFlightItemList : React.FC = () => {
  const [filledFlights, setFilledFlights] = useState([]);
  const allFlights = useFillFlightStore((state) => state.fillFlightRequests);
  const { flightId } = useParams();

  const convertBookingStatusToString = (bookingStatus: CreateBookingStatus): string => {
      if (bookingStatus.result == null) return "no result yet" 
      return bookingStatus.result.reference;
  }
  return (
    <div data-testid="booking-references" className="overflow-y-auto bg-white rounded-md shadow-lg p-4">
      <h2 className="text-xl font-bold mb-2">Filled Flights</h2>
      <ul className="list-inside text-left flex-grow overflow-y-auto">
        {Object.entries(allFlights).map(([flightKey]) => (
          <FilledFlightItem
            key={flightKey} 
            reference={flightKey}
            chosen={flightKey === flightId}
          />
        ))}
      </ul>
    </div>
  );
};

export default FilledFlightItemList;