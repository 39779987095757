import { TextField } from "@mui/material";
import { changeTextFieldNumberValue } from "../../../globalFunctions/utils";
import useFlightRequestStore from "../../../stores/FlightRequestStore";

const BrandedFareFlightCreation: React.FC = () => {
    const { fillFlightRequest, modifyFareType } = useFlightRequestStore();

    const modifyFareTypeHelper = (fareType: string) => {
        return (newTotal: number) => modifyFareType({FareType: fareType, Total: newTotal})
    } 

    const getValue = (fareType: string): number => {
        return fillFlightRequest.fareTypes.filter(x => x.FareType === fareType).reduce((a, b) => a + b.Total, 0)
    }

    return (
        <div className="flex flex-col gap-2">
            <p className="font-bold">Max</p>
            <TextField 
                className="flex-1"
                type="number"
                size="small"
                value={getValue("MAX")}
                onChange={changeTextFieldNumberValue(modifyFareTypeHelper("MAX"))}
                slotProps={{
                    htmlInput: {
                        min: 0
                    }
                }}
            />
            <p className="font-bold">Plus</p>
            <TextField 
                className="flex-1"
                type="number"
                size="small"
                onChange={changeTextFieldNumberValue(modifyFareTypeHelper("PLUS"))}
                value={getValue("PLUS")}
                slotProps={{
                    htmlInput: {
                        min: 0
                    }
                }}/>
            {/* <div className="flex flex-row justify-between pr-7 mt-2">
                <p className="font-bold">Basic</p>
                <p className="text-base">{fillFlightRequest.groups.reduce((acc, group) => group.Total + acc, 0) + fillFlightRequest.totalPassengers - fillFlightRequest.groups.reduce((acc, group) => (group.Total*(group.Adults + group.Children)) + acc, 0)}</p>
            </div> */}

        </div>
    )
}

export default BrandedFareFlightCreation;